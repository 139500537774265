import { Form, FormikProvider, useFormik } from "formik";
import {
  addEbookFields,
  updateEbookFields,
} from "../Config/formFields/fieldArrays";
import FormFieldProvider from "../Uikits/form";
import { FormButton } from "../Uikits/Button";
import axiosInstance from "../Utils/Axios";
import useModalStore from "../Stores/Modal";
import { errorToast, successToast } from "../Utils/Toast";
import {
  AddEbookValidation,
  UpEbookValidation,
} from "../Config/formFields/fieldsValidations";
import dayjs from "dayjs";

export function AOUEbook({ refetch, ebook }) {
  const formik = useFormik({
    initialValues: {
      otherMedias: "",
      title: ebook?.title ?? "",
      purchaseLink: ebook?.purchaseLink ?? "",
      price: ebook?.price ?? 0,
      inStock: JSON.stringify(ebook?.inStock) ?? 0,
      availabilityDate:
        dayjs(parseInt(ebook?.availableFor)).format("YYYY-MM-DD") ?? "",
      availabilityTime:
        dayjs(parseInt(ebook?.availableFor)).format("HH:mm") ?? "",
    },
    validateOnMount: true,
    validationSchema: ebook ? UpEbookValidation : AddEbookValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formData = new FormData();
    const date = dayjs(
      formvalues.availabilityDate + " " + formvalues.availabilityTime
    ).format("YYYY-MM-DD HH:mm");
    const dateTimestamp = dayjs(date).valueOf();

    formData.append("title", formvalues.title);
    formData.append("price", formvalues.price);
    formData.append("purchaseLink", formvalues.purchaseLink);
    formData.append("inStock", JSON.parse(formvalues.inStock));
    formData.append("availableFor", dateTimestamp);

    if (formvalues.otherMedias) {
      formData.append("otherMedias", formvalues.otherMedias);
    }

    let request = ebook
      ? axiosInstance.put("/ebook/update/" + ebook._id, formData)
      : axiosInstance.post("/ebook/add", formData);

    request
      .then((res) => successToast("Ebook ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {(ebook ? updateEbookFields : addEbookFields).map((item, i) => (
          <FormFieldProvider {...item} key={"ebook field lol" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useAuthStore from "../Stores/Auth";
import { MdiFormatListCheckbox } from "./Icons";
import { ToggleThemeBtn } from "./Nav";
import { adminRoutesObj } from "../Utils/Others";

function Sidebar() {
  const [visibleOnMobile, setVOM] = useState(false);
  const logout = useAuthStore((state) => state.logout);
  const user = useAuthStore((state) => state.user);

  const sidebarsLinks = [
    adminRoutesObj.users,
    adminRoutesObj.categories,
    adminRoutesObj.pricing,
    adminRoutesObj.reviews,
    adminRoutesObj.leadsNewLetter,
    adminRoutesObj.leadsProducts,
    adminRoutesObj.newletter,
    adminRoutesObj.products,
    adminRoutesObj.leadPageType,
    adminRoutesObj.trainers,
    adminRoutesObj.trainings,
    adminRoutesObj.trainingQuizzResult,
    adminRoutesObj.eBooks,
  ];
  function getUserPageByGrade() {
    if (user.grade == "SuperAdmin") {
      return sidebarsLinks;
    } else {
      const finalLinks = [];
      for (let i = 0; i < sidebarsLinks.length; i++) {
        const element = sidebarsLinks[i];
        if (user.managerAllowedOptions.includes(JSON.stringify(element.id))) {
          finalLinks.push(element);
        }
      }
      return finalLinks;
    }
  }

  return (
    <aside
      className={"sidebar " + (visibleOnMobile && "sidebarVisibleOnMobile")}
    >
      <section
        className="sidebar-logo"
        style={{
          flexDirection: "column",
        }}
      >
        <img src="/images/logoWhite.png" alt="" />
      </section>
      <section className="sidebar-links">
        {[adminRoutesObj.dashboard, ...getUserPageByGrade()].map(
          (link, index) => (
            <NavLink
              key={"side link" + index}
              to={link.path}
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              {link.label2}
            </NavLink>
          )
        )}
      </section>

      <button onClick={logout}>Se deconnecter</button>

      <span
        className="mobileSidebarToggler"
        onClick={() => setVOM((prev) => !prev)}
      >
        <MdiFormatListCheckbox />
      </span>
      <ToggleThemeBtn forSidebar={true} />
    </aside>
  );
}

export default Sidebar;

import React from "react";
import { BorderButton } from "../Uikits/Button";
import useModalStore from "../Stores/Modal";
import useCustomQuery from "../Hook/query";
import { allEbookUrl } from "../Utils/Queries/training";
import { needAuth } from "../Hocs/NeedAuth";
import { SectionLoader } from "../Uikits/Others";
import { AOUEbook } from "../Components/ebook";
import { deleteItem } from "../Utils/Others";

function ManageEbooks() {
  const showModal = useModalStore((state) => state.showModal);
  const { isLoading, error, data, refetch } = useCustomQuery(allEbookUrl);

  if (isLoading) return <SectionLoader />;
  return (
    <div className="dataManager">
      <h1>Gestion des ebooks</h1>
      <BorderButton
        text="Nouvel ebook"
        onClick={() => showModal(<AOUEbook refetch={refetch} />)}
      />
      <section className="dm-dataList">
        {data.data.length > 0 ? (
          data.data.map((item, i) => (
            <article key={"categorye nb " + i}>
              <img src={item.visual.url} alt="" />
              <p>
                <strong>Titre: </strong>
                {item.title}
              </p>
              <p>
                <strong>Prix: </strong>
                {item.price} euros
              </p>
              <p>
                <a href={item.purchaseLink}>Voir le lien de paiement</a>
              </p>

              <div>
                <button
                  className="updateBtn"
                  onClick={() =>
                    showModal(<AOUEbook ebook={item} refetch={refetch} />)
                  }
                >
                  Modifier
                </button>

                <button
                  className="deleteBtn"
                  onClick={() =>
                    deleteItem(
                      item._id,
                      "/ebook/delete/",
                      "Ebook supprimer !",
                      refetch
                    )
                  }
                >
                  Supprimer
                </button>
              </div>
            </article>
          ))
        ) : (
          <p>Aucun ebook disponible</p>
        )}
      </section>
    </div>
  );
}

export default needAuth(ManageEbooks);

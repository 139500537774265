import About from "../Pages/About";
import AllTestimonials from "../Pages/AllTestimonials";
import Confidentiality from "../Pages/Confidentiality";
import Contact from "../Pages/Contact";
import Dashboard from "../Pages/Dashboard";
import Faq from "../Pages/Faq";
import Home from "../Pages/Home";
import LeadPage from "../Pages/LeadPage";
import Login from "../Pages/Login";
import ManageCategory from "../Pages/ManageCategory";
import ManageFormations from "../Pages/ManageFormations";
import ManageLead from "../Pages/ManageLead";
import ManageLeadPageContent from "../Pages/ManageLeadPageContent";
import ManageLeadPageType from "../Pages/ManageLeadPageType";
import ManageNewletter from "../Pages/ManageNewletter";
import ManageProduct from "../Pages/ManageProduct";
import ManageReview from "../Pages/ManageReview";
import ManageTrainer from "../Pages/ManageTrainer";
import ManageTraining from "../Pages/ManageTraining";
import ManageTrainingChapter from "../Pages/ManageTrainingChapter";
import ManageTrainingQuizz from "../Pages/ManageTrainingQuizz";
import ManageTrainingQuizzResult from "../Pages/ManageTrainingQuizzResult";
import ManageTrainingSubChapter from "../Pages/ManageTrainingSubChapter";
import ManageUser from "../Pages/ManageUser";
import ProductDetails from "../Pages/ProductDetails";
import Products from "../Pages/Products";
import EmailNeededMiddleware from "../Components/products/others";
import { adminRoutesObj } from "./Others";
import ManageEbooks from "../Pages/ManageEbooks";
import Ebooks from "../Pages/Ebooks";

const allBasicRoutes = [
  { id: 50, path: "/", element: <Home /> },
  { id: 52, path: "/about", element: <About /> },
  { id: 54, path: "/contact", element: <Contact /> },
  { id: 56, path: "/faq", element: <Faq /> },
  {
    id: 58,
    path: "/products",
    element: (
      <EmailNeededMiddleware>
        <Products />
      </EmailNeededMiddleware>
    ),
  },
  {
    id: 60,
    path: "/product/:productId",
    element: (
      <EmailNeededMiddleware>
        <ProductDetails />
      </EmailNeededMiddleware>
    ),
  },
  { id: 62, path: "/testimonials", element: <AllTestimonials /> },
  { id: 64, path: "/confidentiality", element: <Confidentiality /> },
  { id: 66, path: "/lead/:leadPageTypeId", element: <LeadPage /> },
  { id: 68, path: "/login", element: <Login /> },
  {
    id: 70,
    path: "/dashboard",
    element: <Dashboard />,
    label: "Tableau de bord",
  },
  {
    id: 72,
    path: "/ebooks",
    element: <Ebooks />,
  },
];

export const allAdministrationRoutes = [
  {
    ...adminRoutesObj.users,
    element: <ManageUser />,
  },
  {
    ...adminRoutesObj.categories,
    element: <ManageCategory />,
  },
  {
    ...adminRoutesObj.pricing,
    element: <ManageFormations />,
  },
  {
    ...adminRoutesObj.reviews,
    element: <ManageReview />,
  },
  {
    ...adminRoutesObj.leads,
    element: <ManageLead />,
  },
  {
    ...adminRoutesObj.newletter,
    element: <ManageNewletter />,
  },
  {
    ...adminRoutesObj.products,
    element: <ManageProduct />,
  },
  {
    ...adminRoutesObj.leadPageType,
    element: <ManageLeadPageType />,
  },
  {
    ...adminRoutesObj.leadPageContent,
    element: <ManageLeadPageContent />,
  },
  {
    ...adminRoutesObj.trainers,
    element: <ManageTrainer />,
  },
  {
    ...adminRoutesObj.trainings,
    element: <ManageTraining />,
  },
  {
    ...adminRoutesObj.trainingChapter,
    element: <ManageTrainingChapter />,
  },
  {
    ...adminRoutesObj.trainingSubchapter,
    element: <ManageTrainingSubChapter />,
  },
  {
    ...adminRoutesObj.trainingQuizz,
    element: <ManageTrainingQuizz />,
  },
  {
    ...adminRoutesObj.trainingQuizzResult,
    element: <ManageTrainingQuizzResult />,
  },
  {
    ...adminRoutesObj.eBooks,
    element: <ManageEbooks />,
  },
];

export const allRoutes = [...allBasicRoutes, ...allAdministrationRoutes];

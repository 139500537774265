import React from "react";
import { noNeedAuth } from "../Hocs/NoNeedAuth";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../Hook/query";
import { allEbookUrl } from "../Utils/Queries/training";
import { SectionLoader } from "../Uikits/Others";
import Countdown from "react-countdown";
function Ebooks() {
  const { isLoading, error, data, refetch } = useCustomQuery(allEbookUrl);
  const navigate = useNavigate();

  if (isLoading) return <SectionLoader />;
  return (
    <div className="products">
      <div className="titleSection">
        <h1>Nos ebooks</h1>
        <p>
          Que vous débutiez dans le commerce en ligne ou que vous cherchiez à
          optimiser vos ventes, ces eBooks regorgent de stratégies, d’astuces
          concrètes et d’outils pour vous aider à atteindre vos objectifs plus
          rapidement. Ne laissez plus le hasard guider votre réussite : accédez
          à des ressources essentielles pour maîtriser chaque étape de votre
          business en e-commerce et faire décoller
        </p>
      </div>
      <br /> <br />
      {data.data.length == 0 ? (
        <p>Aucun ebook disponible</p>
      ) : (
        <>
          <div className="pr-cards flex f-wrap">
            {data.data.map((item, i) => (
              <div
                className="pr-card-section"
                key={"product card" + i}
                onClick={() => window.open(item.purchaseLink, "_blank")}
              >
                <section className="flex ">
                  <img src={item.visual.url} alt="" />
                  <span className="">
                    {item.inStock ? "En stock" : "En rupture de stock"}{" "}
                  </span>
                </section>
                <section className="flex prcs-texts f-wrap">
                  <div className="flex f-column">
                    <p>{item.title} </p>
                    <b>{item.price} €</b>
                    {item.availableFor && (
                      <div className="timerDiv">
                        <span>Disponible encore pour: </span>
                        <Countdown date={parseInt(item.availableFor)} />
                      </div>
                    )}
                  </div>
                </section>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

// inStock
// availableFor

export default noNeedAuth(Ebooks);

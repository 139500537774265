import axiosInstance from "./Axios";
import { errorToast, successToast } from "./Toast";

export function formatTrainingDuration(duration) {
  let intDuration = parseInt(duration);
  if (intDuration >= 60) return Math.round(intDuration / 60) + " heures";
  return intDuration + " minutes";
}

export function calcAndFormatProductSellPercent(originalPrice, sellPrice) {
  const percent = ((sellPrice - originalPrice) / sellPrice) * 100;
  return percent.toFixed(2) + "%";
}

export const adminRoutesObj = {
  dashboard: {
    id: 70,
    path: "/dashboard",
    label: "Tableau de bord",
    label2: "Tableau de bord",
  },
  users: {
    id: 10,
    path: "/manage-user",
    label: "Gestion de utilisateurs",
    label2: "Utilisateurs",
  },
  categories: {
    id: 12,
    path: "/manage-category",
    label: "Gestion des catagories",
    label2: "Catagories",
  },
  pricing: {
    id: 14,
    path: "/manage-pricing",
    label: "Gestions des pricing",
    label2: "Pricing",
  },
  reviews: {
    id: 16,
    path: "/manage-reviews",
    label: "Gestion des commentaires",
    label2: "Commentaires",
  },
  leads: {
    id: 18,
    path: "/manage-lead/:leadType",
    label: "Gestion des lead",
    label2: "Lead",
  },
  leadsNewLetter: {
    id: 180,
    path: "/manage-lead/newletter",
    label: "Gestion des leads Newletter",
    label2: "Lead Newletter",
  },
  leadsProducts: {
    id: 182,
    path: "/manage-lead/product",
    label: "Gestion des leads produits",
    label2: "Lead Produits",
  },
  newletter: {
    id: 20,
    path: "/manage-newletter",
    label: "Gestion de la newletter",
    label2: "Newletter",
  },
  products: {
    id: 22,
    path: "/manage-products",
    label: "Gestion des produits",
    label2: "Produits",
  },
  leadPageType: {
    id: 24,
    path: "/manage-leadPageType",
    label: "Gestion des pages leads et leurs contenu",
    label2: "Type de lead",
  },
  leadPageContent: {
    id: 26,
    path: "/manage-leadPageContents/:leadPageTypeId/:leadPageTypeLabel",
    label: "Gestion de contenu de lead",
    label2: "Contenu de lead",
  },
  trainers: {
    id: 28,
    path: "/manage-trainer",
    label: "Gestion des formateurs",
    label2: "Formateurs",
  },
  trainings: {
    id: 30,
    path: "/manage-training",
    label: "Gestion des formations",
    label2: "Formations",
  },
  trainingChapter: {
    id: 32,
    path: "/manage-training/:trainingId/chapters",
    label: "Gestion des chapitres de formation",
    label2: "Chapitres de formation",
  },
  trainingSubchapter: {
    id: 34,
    path: "/manage-training/:chapterId/subchapters",
    label: "Gestion des sous chapitres de formation",
    label2: "Sous chapitre de formation",
  },
  trainingQuizz: {
    id: 36,
    path: "/manage-training/:chapterId/quizz",
    label: "Gestion des quizzs",
    label2: "Quizz",
  },
  trainingQuizzResult: {
    id: 38,
    path: "/quizz-student-results",
    label: "Gestion des resultats de quizz",
    label2: "Resultats de quizz",
  },
  eBooks: {
    id: 40,
    path: "/manage-ebooks",
    label: "Gestion des ebooks",
    label2: "Ebooks",
  },
};

export function deleteItem(itemId, itemApiUrl, itemSuccessM, refetch) {
  axiosInstance
    .delete(itemApiUrl + itemId)
    .then((res) => successToast(itemSuccessM))
    .catch((err) => errorToast("Une erreur survenue"))
    .finally(() => {
      refetch();
    });
}

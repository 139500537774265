import { Form, FormikProvider, useFormik } from "formik";
import { certificateConfigFields } from "../Config/formFields/fieldArrays";
import FormFieldProvider from "../Uikits/form";
import { FormButton } from "../Uikits/Button";
import axiosInstance from "../Utils/Axios";
import useModalStore from "../Stores/Modal";
import { errorToast, successToast } from "../Utils/Toast";

export function AOUECertificateConfig({ refetch, ebook }) {
  const formik = useFormik({
    initialValues: {
      logo: "",
      tagline: "",
    },
    validateOnMount: true,
    // validationSchema: ebook ? UpEbookValidation : AddEbookValidation,
    onSubmit: handleSubmit,
  });
  const { values, isValid, isSubmitting, setSubmitting } = formik;
  const hideModal = useModalStore((state) => state.hideModal);

  function handleSubmit(formvalues) {
    const formData = new FormData();
    formData.append("logo", formvalues.logo);
    formData.append("tagline", formvalues.tagline);
    // if (formvalues.otherMedias) {
    //   formData.append("otherMedias", formvalues.otherMedias);
    // }
    let request = axiosInstance.post("/training/certificate-config/", formData);

    request
      .then((res) => successToast("Configuration ajouter !"))
      .catch((err) => errorToast("Une erreur est survenue"))
      .finally(() => {
        hideModal();
        refetch();
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        {certificateConfigFields.map((item, i) => (
          <FormFieldProvider {...item} key={"certif config field" + i} />
        ))}
        <FormButton
          isValid={isValid}
          text="Enregistrer"
          isSubmitting={isSubmitting}
        />
      </Form>
    </FormikProvider>
  );
}

import {
  appName,
  autorAudioOrVideoField,
  autorName,
  autorPicture,
  autorText,
  availabilityDate,
  availabilityTime,
  categoryLabelLevel,
  categoryTypegLevel,
  certificateLogo,
  certificateTagline,
  ebookInStockField,
  ebookPriceField,
  ebookPurchaseLikField,
  ebookTitleField,
  ebookVisualField,
  emailField,
  formationDetails,
  formationName,
  formationPrice,
  formationTime,
  lpButtonLink,
  lpButtontext,
  lpGallery,
  lpParagraph,
  lpPdf,
  lpPdfImg,
  lpTypeLabel,
  lpVideo,
  messageEditorField,
  messageField,
  nameField,
  passwordConfirmationField,
  passwordField,
  productCategory,
  productCompetitorLink,
  productCompetitorPubLink,
  productDesc,
  productId,
  productIsVisible,
  productMarkets,
  productName,
  productPictures,
  productPrice,
  productPublicationDateTime,
  productSupplierLink,
  productSupplierPrice,
  quizzQuestionField,
  quizzRightAnswerField,
  quizzSuggestedAnswerField,
  stripeLinkField,
  subject2Field,
  subjectField,
  trainerEmail,
  trainerName,
  trainerPicture,
  trainingDesc,
  trainingDuration,
  trainingIsFree,
  trainingLevel,
  trainingMentorId,
  trainingMiniature,
  trainingRessources,
  trainingStudentsC,
  trainingSubTitle,
  trainingTags,
  trainingTitle,
  trainingVideo,
  userEmailField,
  userGradeField,
  userNameField,
  userPasswordField,
} from "./fields";
import { userGradeOptions } from "./others";

export const loginFields = [emailField, passwordField];

export const giveEmailFields = [emailField];

export const contactFields = [
  nameField,
  emailField,
  subjectField,
  messageField,
];

export const updateAccountFields = [nameField, emailField, passwordField];

export const formationsFields = [
  appName,
  formationName,
  formationPrice,
  formationTime,
  formationDetails,
  stripeLinkField,
];

export const formationsAvailabilityFields = [
  availabilityDate,
  availabilityTime,
];

export const reviewsFields = [
  appName,
  autorName,
  autorText,
  autorPicture,
  autorAudioOrVideoField,
];

export const productsFields = [
  productCategory,
  productName,
  productPrice,
  productDesc,
  productPictures,
  productCompetitorLink,
  productCompetitorPubLink,
  productSupplierPrice,
  productSupplierLink,
  productMarkets,
  productId,
  productPublicationDateTime,
];

export const upProductsFields = [
  productCategory,
  productName,
  productPrice,
  productDesc,
  productCompetitorLink,
  productCompetitorPubLink,
  productSupplierPrice,
  productSupplierLink,
  productMarkets,
  productId,
  productIsVisible,
  productPublicationDateTime,
];

export const addLPTypeFields = [lpTypeLabel];

export const addLPParagraphFields = [lpParagraph];

export const addLPButtonFields = [lpButtontext, lpButtonLink];

export const addLPVideoFields = [lpVideo];

export const addLPPdfFields = [lpPdfImg, lpPdf];

export const addLPVGalleryFields = [lpGallery];

export const addNewletterMessageFields = [subject2Field, messageEditorField];

export const updateUserFields = [
  userNameField,
  userEmailField,
  userPasswordField,
  passwordConfirmationField,
];

export const addNewUserFields = [userGradeField, ...updateUserFields];

export const addNewTrainerFields = [trainerName, trainerEmail, trainerPicture];

export const addNewTrainingFields = [
  trainingTitle,
  trainingSubTitle,
  trainingLevel,
  trainingTags,
  trainingDesc,
  trainingMiniature,
  trainingMentorId,
  trainingIsFree,
  trainingStudentsC,
];

export const addNewTrainingChapterFields = [
  trainingTitle,
  trainingSubTitle,
  trainingDesc,
  trainingVideo,
  trainingRessources,
];

export const addNewTrainingSubChapterFields = [
  trainingTitle,
  trainingDesc,
  trainingDuration,
  trainingVideo,
  trainingRessources,
];

export const addNewTrainingQuizzFields = [
  quizzQuestionField,
  quizzSuggestedAnswerField,
  quizzRightAnswerField,
];

export const addOrUpdateCategoryFields = [
  categoryLabelLevel,
  categoryTypegLevel,
];

export const addEbookFields = [
  ebookTitleField,
  ebookPriceField,
  ebookPurchaseLikField,
  ebookVisualField,
  ebookInStockField,
  ...formationsAvailabilityFields,
];

export const updateEbookFields = [
  ebookTitleField,
  ebookPriceField,
  ebookPurchaseLikField,
  ebookVisualField,
  ebookInStockField,
  ...formationsAvailabilityFields,
];

export const certificateConfigFields = [certificateLogo, certificateTagline];
